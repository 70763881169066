
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
                <v-layout row wrap px-0 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.team_members.edit_path">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                    {{ item.title.toUpperCase() }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>
                </v-layout>
            <form @submit.prevent="updateTeamMembers" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_name_ku" type="text" :label="$store.getters.language.data.team_members.team_member_name_ku" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_name_en" type="text" :label="$store.getters.language.data.team_members.team_member_name_en" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_name_ar" type="text" :label="$store.getters.language.data.team_members.team_member_name_ar" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_index" type="number" :label="$store.getters.language.data.team_members.team_member_index" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_text_ku" type="textarea" :label="$store.getters.language.data.team_members.team_member_text_ku" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_text_en" type="textarea" :label="$store.getters.language.data.team_members.team_member_text_en" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_text_ar" type="textarea" :label="$store.getters.language.data.team_members.team_member_text_ar" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="team_members.team_member_image" type="textarea" :label="$store.getters.language.data.team_members.team_member_image" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.team_members.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/team_members.request.js'
    export default {
        data() {
            return {
                team_members: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                
            }
        },
        computed: {
           
            page(){
                const pageKey = 'TEAMMEMBERS'
                return {}
            }
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneTeamMembers()
        },
        methods: {
            getOneTeamMembers() {
                this.loading = true
                requests.getOneTeamMembers(this.id).then(r =>{
                    if (r.status == 200) {
                        this.team_members = r.data.team_members
                        
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read TeamMembers',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read TeamMembers',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateTeamMembers() {
                this.loading = true
                requests.updateTeamMembers(this.id,this.team_members).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'TeamMembers Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    