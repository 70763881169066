
import axios from 'axios';
export default {
    
    async createTeamMembers(params)  {
        return await axios.post(`team_members/create.php` , params)
    },
    async getAllTeamMembers()  {
        return await axios.get(`team_members/readAll.php`)
    },
    async getOneTeamMembers(team_member_id)  {
        return await axios.get(`team_members/readById.php?team_member_id=${team_member_id}`)
    },
    async getTeamMembersByColumn(column , value)  {
        return await axios.get(`team_members/readByColumn.php?column=${column}&value=${value}`)
    },
    async deleteTeamMembers(team_member_id)  {
        return await axios.post(`team_members/delete.php?team_member_id=${team_member_id}`)
    },
    async updateTeamMembers(team_member_id , params)  {
        return await axios.post(`team_members/update.php?team_member_id=${team_member_id}` , params)
    }
}